<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-progress-linear
          :active="getPageLoading"
          :indeterminate="getPageLoading"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="primary"
              @click="$router.push('/app/page/create')"
            >
              <v-icon>mdi-plus</v-icon>
              Add new page
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>
          Pages
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="getPages.length ? getPages : []"
          item-key="id"
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:body="props">
            <draggable
              :disabled="getPageLoading"
              :list="props.items"
              tag="tbody"
              @update="reorderDebounceEvent(props.items)"
            >
              <tr v-for="(page, index) in props.items" :key="index">
                <td>
                  <v-icon small class="grab-icon">
                    mdi-arrow-all
                  </v-icon>
                </td>
                <td>
                  <div class="d-flex align-center">
                    <p class="ma-0 font-weight-medium">
                      <template v-if="page.icon">
                        <v-icon>{{ page.icon }}</v-icon>
                      </template>
                      &nbsp;&nbsp;{{ page.title }}
                    </p>
                  </div>
                </td>
                <td>
                  <v-icon v-if="page.custom">mdi-check</v-icon>
                </td>
                <td>
                  <div class="d-flex align-center">
                    <p class="ma-0 font-weight-medium">
                      {{ getPermissions(page) }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="$router.push(`/app/page/${page.id}/edit`)"
                        >
                          <v-icon>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="danger"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="showRemoveDialog(page)"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="dialog" max-width="290">
        <v-card v-if="selected">
          <v-card-title class="text-h6">
            Delete {{ selected.title }}
          </v-card-title>
          <v-card-text>
            The {{ selected.title }} page will be deleted permanently. Are you
            sure?
            <v-checkbox
              outlined
              :label="`Delete ${selected.model} model`"
              v-if="!selected.custom"
              v-model="shouldRemoveModel"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="danger"
              text
              :loading="getPageLoading"
              @click="deletePage"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Draggable from "vuedraggable";
import _ from "lodash";

export default {
  metaInfo: {
    title: "Page"
  },
  components: {
    Draggable
  },
  data() {
    return {
      page: null,
      search: "",
      selected: null,
      shouldRemoveModel: false,
      reorderDebounceEvent: null,
      dialog: false,
      headers: [
        { text: "", sortable: false },
        { text: "Title", value: "title", sortable: false },
        { text: "Custom", value: "custom", sortable: false },
        { text: "Permission", value: "permission", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ]
    };
  },
  mounted() {
    this.reorderDebounceEvent = _.debounce(this.doPageReorder, 300);
  },
  methods: {
    ...mapActions(["removePage", "reorderPage", "setPageLoading"]),
    async doPageReorder(items) {
      this.setPageLoading(true);
      try {
        const pageOrders = _.map(items, (item, index) => ({
          id: item.id,
          order: index
        }));
        await this.reorderPage(pageOrders);
        this.$store.dispatch("showSnackbar", "Pages reordered");
      } catch (error) {
        console.error("DEBUG: reorderError", error);
        this.$store.dispatch("showSnackbar", "Oops, something went wrong!");
      }
      this.setPageLoading(false);
    },
    getPermissions(page) {
      return _.join(_.get(page, "actions"));
    },
    showRemoveDialog(item) {
      this.selected = item;
      this.dialog = true;
    },
    async deletePage() {
      this.$store.dispatch("setPageLoading", true);
      try {
        if (this.custom) {
          await this.$store.dispatch("removePage", { id: this.selected.id });
        } else {
          await this.$store.dispatch("removePage", {
            id: this.selected.id,
            payload: {
              shouldRemoveModel: +this.shouldRemoveModel
            }
          });
        }
      } catch (error) {
        console.error("DEBUG: error", error);
        this.$store.dispatch("showSnackbar", "Oops, something went wrong!");
        this.$store.dispatch("setPageError", error);
      }

      this.$store.dispatch("setPageLoading", false);
      this.selected = null;
      this.dialog = false;
    }
  },
  computed: {
    ...mapGetters(["getPages", "getPageLoading"])
  }
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        padding: 0;
      }

      .grab-icon {
        width: 100%;
        cursor: move;
      }

      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
